import React from "react"
import { Typography } from "@mui/material"
import { toast } from "react-toastify"
import { calculateTotalSum } from "../util"

const BenefitsSelectedTable = ({ totalAllowed, totalSelected }) => {
  return (
    <>
      <table>
        <tr>
          <td>
            <Typography>Total Allowed </Typography>
          </td>
          <td>
            <Typography>{totalAllowed}</Typography>
          </td>
        </tr>
        <tr>
          <td>
            <Typography>Total Selected </Typography>
          </td>
          <td>
            <Typography>{totalSelected}</Typography>
          </td>
        </tr>
        <tr>
          <td>
            <Typography>Prending Amount </Typography>
          </td>
          <td>
            <Typography>
              {totalAllowed - totalSelected}
            </Typography>
          </td>
        </tr>
      </table>
    </>
  )
}

export default BenefitsSelectedTable
